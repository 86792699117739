import { Button, Tag } from "antd";
import { isSortField, tableSorter } from "../../../../shared/utils/table";
import styles from "./index.module.scss";
import { statusToColor, statusToText } from "./utils";
export const useColumns = ({
  sortField,
  sortOrder,
  setIsUploadReportVisible,
}: any) => [
  {
    title: "Creation Date",
    dataIndex: "createdAt",
    render: (text: any) => (
      <div className="text-center">{new Date(text).toLocaleDateString()}</div>
    ),
    width: 150,
    sorter: {
      compare: (a: any, b: any) => tableSorter(a.createdAt, b.createdAt),
    },
    sortOrder: isSortField(sortField, sortOrder, "createdAt"),
  },
  {
    title: `Reference Number`,
    dataIndex: "referenceNumber",
    width: 150,
    render: (referenceNumber: any) => (
      <div className="text-center">{referenceNumber}</div>
    ),
  },
  {
    title: `Survey Number`,
    dataIndex: "surveyNumber",
    width: 150,
    render: (surveyNumber: any) => (
      <div className="text-center">{surveyNumber}</div>
    ),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (amount: any) => <div className="text-center">₹{amount}</div>,
    width: 150,
  },
  {
    title: "Applicant's Name",
    dataIndex: "propertyAddress",
    width: 200,
    render: (propertyAddress: any) => (
      <div className="text-center">
        {propertyAddress?.firstName} {propertyAddress?.lastName}
      </div>
    ),
  },
  {
    title: "Applicant's Phone",
    dataIndex: "propertyAddress",
    width: 200,
    render: (propertyAddress: any) => (
      <div className="text-center">{propertyAddress?.phone}</div>
    ),
  },
  {
    title: "Valuation Report",
    dataIndex: "status",
    render: (status: any) => (
      <>
        <Button onClick={setIsUploadReportVisible(true)}>Upload</Button>
      </>
    ),
    width: 200,
    sorter: true,
    sortOrder: isSortField(sortField, sortOrder, "status"),
  },
];
