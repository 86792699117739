export const sidebarUtil = {
  sideBar: [
    {
      key: "1",
      label: "Valuations",
      icon: "allSvgIcon",
    },
    {
      key: "2",
      label: "Create New Request",
      icon: "allSvgIcon",
    },
    {
      key: "3",
      label: "Bank Profile",
      icon: "allSvgIcon",
    },
    {
      key: "4",
      label: "Settings",
      icon: "allSvgIcon",
    },
  ],
};
