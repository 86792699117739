// import flagSvgIcon from "../../../svgs/flag_svg";
// import allSvgIcon from "../../../svgs/all_svg";
// import returnSvgIcon from "../../../svgs/return_svg";
// import activeSvgIcon from "../../../svgs/active_svg";

export const ICON_MAPPER = {
  // allSvgIcon: allSvgIcon,
  // activeSvgIcon: activeSvgIcon,
  // flagSvgIcon: flagSvgIcon,
  // returnSvgIcon: returnSvgIcon,
};
